import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FeedIcon from "@mui/icons-material/Feed";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PrintIcon from "@mui/icons-material/Print";
import VerifiedIcon from "@mui/icons-material/Verified";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import PaymentIcon from "@mui/icons-material/Payment";
import "../styles.css";

export const mainListItems = (
  <React.Fragment>
    <Link to={`/dashboard`}>
      <div>
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/details`}>
      <div>
        <ListItemButton>
          <ListItemIcon>
            <FeedIcon />
          </ListItemIcon>
          <ListItemText primary="Case Review" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/detailview`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <FeedIcon />
          </ListItemIcon>
          <ListItemText primary="KYC" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/creditinformation`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Credit Information"
            className="list-item-text"
          />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/financialdetails`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Company Financials"
            className="list-item-text"
          />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/bankstatement`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Bank Statement" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/gst`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="GST Document" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/taxfiling`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <HistoryEduIcon />
          </ListItemIcon>
          <ListItemText primary="Tax Filing" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/collateralvalue`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Collateral Value" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
   
  </React.Fragment>
);
export const mainListItemsIndividual = (
  <React.Fragment>
    <Link to={`/dashboard`}>
      <div>
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/details`}>
      <div>
        <ListItemButton>
          <ListItemIcon>
            <FeedIcon />
          </ListItemIcon>
          <ListItemText primary="Case Review" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/detailview`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <FeedIcon />
          </ListItemIcon>
          <ListItemText primary="KYC" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/creditinformation`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Credit Information"
            className="list-item-text"
          />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/bankstatement`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Bank Statement" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    {/* <Link to={`/insurance`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <HealthAndSafetyIcon />
          </ListItemIcon>
          <ListItemText primary="Insurance" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link> */}
    <Link to={`/payslip`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Salary Slip" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/taxfiling`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <HistoryEduIcon />
          </ListItemIcon>
          <ListItemText primary="Tax Filing" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
    <Link to={`/collateralvalue`}>
      <div>
        <ListItemButton sx={{ ml: 1 }}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Collateral Value" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
  </React.Fragment>
);
export const mainListItems2 = (
  <React.Fragment>
    <Link to={`/dashboard`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Dashboard">
            <DashboardIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Dashboard" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/details`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Case Review">
            <FeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Case Review" className="list-item-text" />
      </ListItemButton>
    </Link>
    {/* <Link to={`/notificationServices`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Notification Services">
            <NotificationsNoneIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary="Notification Services"
          className="list-item-text"
        />
      </ListItemButton>
    </Link> */}
  </React.Fragment>
);
export const externalMenu = (
  <React.Fragment>
    <Link to={`/details`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Case Review">
            <FeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Case Review" className="list-item-text" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const verificationSideNav = (
  <React.Fragment>
    <Link to={`/dashboard`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Dashboard">
            <DashboardIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Dashboard" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/details`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Case Review">
            <FeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Case Review" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/verification`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Initiate Verification">
            <VerifiedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary="Initiate Verification"
          className="list-item-text"
        />
      </ListItemButton>
    </Link>
    <Link to={`/collateralverification`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Collateral Verification">
            <ApartmentIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary="Collateral Verification"
          className="list-item-text"
        />
      </ListItemButton>
    </Link>
    <Link to={`/personaldiscussion`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Personal Discussion">
            <PeopleIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary="Personal Discussion"
          className="list-item-text"
        />
      </ListItemButton>
    </Link>
    <Link to={`/personalinterview`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Approval Conditions">
            <PeopleIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary="Approval Conditions"
          className="list-item-text"
        />
      </ListItemButton>
    </Link>
    <Link to={`/finaldecision`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Pricing and Final Decision">
            <ContactPhoneIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Final Decision" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/repaymentSchedule`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="EMI Details">
            <PaymentIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="EMI Details" className="list-item-text" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const disbursementSideNav = (
  <React.Fragment>
    <Link to={`/dashboard`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Dashboard">
            <DashboardIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Dashboard" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/details`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Case Review">
            <FeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Case Review" className="list-item-text" />
      </ListItemButton>
    </Link>
    {/* <Link to={`/summary`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Summary">
            <SummarizeIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Summary" className="list-item-text" />
      </ListItemButton>
    </Link> */}
    <ListItemButton>
      <ListItemIcon>
        <InboxIcon />
      </ListItemIcon>
      <ListItemText primary="Disbursement" className="list-item-text" />
    </ListItemButton>
    <Link to={`/checklist`}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
          <ChecklistIcon />
        </ListItemIcon>
        <ListItemText primary="Checklist" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/generate`}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
          <SaveAsIcon />
        </ListItemIcon>
        <ListItemText primary="Generate" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/update`}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
          <SystemSecurityUpdateGoodIcon />
        </ListItemIcon>
        <ListItemText primary="Update" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/printcontract`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Print Contract">
            <PrintIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Print Contract" className="list-item-text" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const verificationSideNavBranchManager = (
  <React.Fragment>
    <Link to={`/details`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Case Review">
            <FeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Case Review" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/documentVerification`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Verification">
            <VerifiedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Verification" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/technicalVerification`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Technical Verification">
            <ApartmentIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary="Technical Verification"
          className="list-item-text"
        />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const verificationSideNavLawyer = (
  <React.Fragment>
    <Link to={`/details`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Case Review">
            <FeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Case Review" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/legalVerification`}>
      <ListItemButton sx={{ ml: 1 }}>
        <ListItemIcon>
          <Tooltip title="Legal Verification">
            <ApartmentIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Legal Verification" className="list-item-text" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const secondaryListItems = (
  <React.Fragment>
    <Link to={`/verification`}>
      <ListItemButton>
        <ListItemIcon>
          <Tooltip title="Initiate Verification">
            <VerifiedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Go to Verifications" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const secondaryListItemsDetails = (
  <React.Fragment>
    <Link to={`/detailview`}>
      <div>
        <ListItemButton>
          <ListItemIcon>
            <FeedIcon />{" "}
          </ListItemIcon>
          <ListItemText primary="Go to Details" className="list-item-text" />
        </ListItemButton>
      </div>
    </Link>
  </React.Fragment>
);
export const notificationServices = (
  <React.Fragment>
    <Link to={`/notificationServices`}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
          <SystemSecurityUpdateGoodIcon />
        </ListItemIcon>
        <ListItemText primary="Whatsapp Blast" className="list-item-text" />
      </ListItemButton>
    </Link>
    <Link to={`/topUpOffer`}>
      <ListItemButton sx={{ pl: 4 }}>
        <ListItemIcon>
          <SaveAsIcon />
        </ListItemIcon>
        <ListItemText primary="Top Up Offer" className="list-item-text" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
