import React, { useState } from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  TextField,
  Divider,
  Link,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import "./../styles.css";
import Typography from "@mui/material/Typography";
import Axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { base_url1 } from "../../constants";
import List from "@mui/material/List";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import TaskIcon from "@mui/icons-material/Task";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import Footer from "./../Common/footer";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import DownloadIcon from "@mui/icons-material/Download";
import "./../styles.css";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});
import { getCibilReports, getDownloadCibil } from "../apiCalls/api";
import { Alert } from "@mui/material";

const drawerWidth = 240;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const convertGMTtoIST = (gmtDate) => {
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };

  const date = new Date(gmtDate);
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime = new Date(date.getTime());
  return istTime.toLocaleString("en-IN", options);
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function CibilReport() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [serverError, setServerError] = React.useState(false);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [noDocument, setNoDocument] = React.useState(false);


  const downloadCibilFile = async (e, loan_id) => {
    setNoDocument(false);
    try {
      const result = await getDownloadCibil(loan_id);
      window.open(result.data.data.azure_url, "_blank");
    } catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
      setNoDocument(true);
    }
  }
  const columns = [
    {
      field: "Sr No.",
      headerName: "Sr.No.",
      flex: 1,
      maxWidth: 70,
      //   type: "number",
      sortable: true,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    {
      field: "Reference Number",
      headerName: "Reference Number",
      flex: 1,
      minWidth: 50,
    },
    // {
    //   field: "LCode",
    //   headerName: "L Code",
    //   flex: 1,
    //   minWidth: 50,
    // },
    {
      field: "LCategory",
      headerName: "Loan Category",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "LoanType",
      headerName: "Loan Type",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "Customer Name",
      headerName: "Customer Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "Loan Amount",
      headerName: "Requested Loan Amount",
      flex: 1.3,
      minWidth: 150,
      renderCell: (params) => {
        const formattedLoanAmount = isNaN(params.row["Loan Amount"])
          ? params.row["Loan Amount"]
          : Number(params.row["Loan Amount"]).toLocaleString();
        return <div>{formattedLoanAmount}</div>;
      },
    },
    {
      field: "Date",
      headerName: "Created Date",
      flex: 1,
      minWidth: 90,
      type: "date",
      valueFormatter: (params) => {
        if (params.value === "Unknown Date") {
          return "N/A";
        }

        const date = new Date(params.value);
        const istDate = convertGMTtoIST(date);
        return istDate.toLocaleString();
      },
    },
    {
      // editable: true,
      field: "Loan Status",
      flex: 1.3,
      minWidth: 50,
      align: "center",
      headerName: "Loan Status",
      renderCell: (params) => {
        return (
          <div
            className={`approved-${params.row["Loan Status"]
              ? params.row["Loan Status"].toString().toLowerCase()
              : ""
              }`}
          >
            {params.row["Loan Status"]}
          </div>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Cibil Pull Report",
      headerAlign: "center",
      width: 90,
      flex: 1,
      minWidth: 90,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Download Document" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => downloadCibilFile(e, params.row["Sr No."])}
                  style={{ color: "#3f48cc" }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const email = sessionStorage.getItem("Email");
  }, []);

  function getRowId(row) {
    return row.internalId;
  }

  useEffect(() => {
    async function getData() {
      setisAPILoading(true);
      try {
        const response = await getCibilReports();
        setNoDocument(false);
        console.log(response.data.data);
        setServerError(false);
        if (response.data.data) {
          setData(response.data.data);
          setisAPILoading(false);
          console.log(data);
        } else {
          setData(result.data.data);
          setisAPILoading(false);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          console.error(error);
          setServerError(true);
        }
        setisAPILoading(false);
        setServerError(true);
      }
    }
    getData();
  }, []);
  return (
    <>
      <div>
        {" "}
        <ThemeProvider theme={responsiveFontSizes(theme)}>
          <Box sx={{ display: "flex", mb: 6, mt: 5 }}>
            <CssBaseline />
            <AppBar position="absolute">
              <Toolbar
                sx={{
                  pr: "24px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  Cibil Report
                </Typography>
                <Tooltip title="Logout " sx={{ mt: -1 }}>
                  <IconButton color="inherit" href="/login">
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
          </Box>
          <Footer />
          <Grid>
            <div style={{ width: "98%", height: "90%" }}>
              <div
                style={{ display: "flex", justifyContent: "center", fontSize: "1rem" }}>
                <b>Cibil Report Details</b>
              </div>
              {serverError ? (<>
                <>
                  <Alert severity="error" sx={{ m: 2 }}>
                    We are facing Internal Issues now. Contact Admin or Try Again Later.
                  </Alert>
                </>
              </>) : (<>
                {isAPILoading ? (
                  <Grid item xs={12}>
                    {/* <LinearProgress
                      sx={{
                        color: "#3f48cc",
                      }}
                      size={40}
                      thickness={4}
                    /> */}
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        marginTop: "150px",
                      }}
                    >
                      <CircularProgress />
                      <Typography>Loading...</Typography>
                    </div>
                  </Grid>
                ) : (<>
                  {data.length > 0 ? (<>
                    <div className="table-container">

                      {noDocument && (
                        <Alert severity="error" sx={{m:2}}>
                          There is no document available for download.
                        </Alert>
                      )}

                      <DataGrid
                        rows={data}
                        columns={columns.concat(actionColumn)}
                        getRowId={(row) => row["Sr No."]}
                        slots={{ toolbar: GridToolbar }}
                        density="comfortable"
                        disableSelectionOnClick={true}
                        sx={{
                          ml: 3, mr: 3,
                          border: 2,
                          borderColor: "black",
                          // height:"75vh",
                          "&.MuiDataGrid-root": {
                            border: "none ",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                          {
                            outline: "none !important",
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            whiteSpace: "normal !important",
                            wordWrap: "break-word !important",
                          },
                        }}
                      />
                    </div>
                  </>) : (<>
                    <Alert severity="info" sx={{ m: 2 }}>
                      There is no data available at the moment.
                    </Alert>
                  </>)}
                </>)}
              </>)}
            </div>
          </Grid>
        </ThemeProvider>
      </div>
    </>
  )
}