import React from "react";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        background: "#3f48cc",
        color: "#ffffff",
        justifyContent: "center",
        position: "fixed",
        width: "100%",
        top: "95%",
        height: "5%",
      }}
      style={{zIndex:1}}
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 500,
          fontSize: "16px",
        }}
      >
        Powered By <b>&nbsp; Corestrat&nbsp; </b> © {new Date().getFullYear()}{" "}
        &nbsp;
        <Link
          color="inherit"
          href="https://corestrat.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          corestrat.ai.
        </Link>{" "}
        &nbsp; All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
