import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  verificationSideNav,
  secondaryListItems,
  disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  getDisbursementChecklist,
  readyforDisbursement,
  getDisbursementChecklistNew,
  submitChecklist,
  getDisbursementDetails,
  submitDisbursal,
  getTransactionDetails,
  getDownloadUrl,
} from "./../apiCalls/api";
import { useState } from "react";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import AppHeader from "./../Common/header";
import {
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function Checklist() {
  const [editRowsModel, setEditRowsModel] = useState({});

  const handleEditCellChange = (params) => {
    const updatedModel = { ...editRowsModel };
    updatedModel[params.id] = params.model;
    setEditRowsModel(updatedModel);
  };
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [pdcCollected, setpdcCollected] = React.useState(false);
  const [MITCDone, setMITCDone] = React.useState(false);
  const [isPDCapiCalled, setisPDCapiCalled] = React.useState(false);
  const [rows, setrows] = React.useState([]);
  const [disburseOpen, setDisburseOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [ifsc, setIfsc] = useState(null);
  const [accno, setAccno] = useState(null);
  const [name, setName] = useState(null);
  const [disburseAmt, setDisburseAmt] = useState(null);
  const [isDisbursed, setIsDisburse] = useState(false);
  const [loanDisburse, setLoanDisburse] = useState(null);
  const [readyDisburse, setReadyDisburse] = useState(false);
  const [checklistSubmit, setChecklistSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRoleHead, setIsRoleHead] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openUTR, setOpenUTR] = useState(false);
  const [refId, setRefId] = useState(null);
  const [utr, setUtr] = useState(null);
  const [transAmt, setTransAmt] = useState(null);
  const [transStatus, setTransStatus] = useState(null);

  const currentUser = useSelector((state) => state.currentUserDetails);
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      flex: 1,
      maxWidth: 50,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "doc_url",
      headerName: "Document URL",
      flex: 1,
      minWidth: 90,
      renderCell: (params) =>
        params.row.doc_url ? (
          <Button onClick={(e) => downloadSingleFile(params.row)}>
            Download Document
          </Button>
        ) : null,
    },
    {
      field: "is_checked",
      headerName: "System Check",
      minWidth: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(event) => {
            const updatedRows = rows.map((row) =>
              row.id === params.id
                ? { ...row, is_checked: event.target.checked }
                : row
            );
            setrows(updatedRows);
          }}
          disabled={true}
        />
      ),
    },
    {
      field: "user_checked",
      headerName: "Manual Check",
      minWidth: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(event) => {
            const updatedRows = rows.map((row) =>
              row.id === params.id
                ? { ...row, user_checked: event.target.checked }
                : row
            );
            setrows(updatedRows);
          }}
          disabled={
            currentUser.role_name != "credit_head" &&
            currentUser.role_name != "credit_ops"
          }
        />
      ),
    },
  ];
  const handleClick = () => {
    setOpen1(!open1);
  };
  const downloadSingleFile = async (e) => {
    console.log(e);
    try {
      const result = await getDownloadUrl(e.doc_url);
      window.open(result.data.response_data.download_url, "_blank");
    } catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const [open, setOpen] = React.useState(true);
  const [allUserChecked, setallUserChecked] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpen = async () => {
    navigate("/confirmDisbursement")
    // setDisburseOpen(true);
    // setLoading(true);
    // try {
    //   const result = await getDisbursementDetails(reduxData);
    //   setIfsc(result.data.response_data.beneficiary_account_ifsc);
    //   setAccno(result.data.response_data.beneficiary_account_number);
    //   setName(result.data.response_data.beneficiary_name);
    //   setDisburseAmt(result.data.response_data.disbursement_amount);
    //   setIsDisburse(result.data.response_data.is_disbursed);
    //   setLoanDisburse(result.data.response_data.loan_id);
    //   setReadyDisburse(result.data.response_data.ready_for_disbursement);
    //   setLoading(false);
    // } catch (error) {
    //   if (error.message === "AxiosError: Request failed with status code 401") {
    //     setIsErrorModalOpen(true);
    //     console.log(error);
    //   }
    // }
  };

  const handleClose = () => {
    setDisburseOpen(false);
  };

  const handleTabChange = async (event, newValue) => {
    setSelectedTab(newValue);
    {
      selectedTab === 0 ? setLoading(true) : setLoading(false);
    }
    try {
      const result1 = await getTransactionDetails(reduxData);
      setUtr(result1.data.response_data.utr);
      setRefId(result1.data.response_data.reference_id);
      setTransAmt(result1.data.response_data.transaction_amount);
      setTransStatus(result1.data.response_data.transaction_status);
      setLoading(false);
    } catch (error) {
      if (error.message) {
        setErrorMessage(error.message);
      }
    }
  };

  const submitPDC = async () => {
    console.log(pdcCollected);
    try {
      setisPDCapiCalled(true);
      const result = await readyforDisbursement(
        reduxData,
        pdcCollected,
        MITCDone
      );
      if (result) {
        console.log("results", result);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  useEffect(() => {
    async function getData() {
      try {
        const result = await getDisbursementChecklistNew(reduxData);
        console.log("results", result.data.response_data);
        const json1 = result.data.response_data;
        const json2 = Object.keys(json1).map((key, index) => {
          const title = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
          return {
            id: index + 1,
            title: title, // Convert underscores to spaces
            details: json1[key].details,
            doc_url: json1[key].doc_url,
            is_checked: json1[key].is_checked,
            user_checked: json1[key].user_checked,
          };
        });
        console.log(json2);
        setrows(json2);
        const allUserChecked = json2.every((item) => item.user_checked);

        // If allUserChecked is true, handle it accordingly
        if (allUserChecked) {
          setallUserChecked(false);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const submitCheckListResults = async () => {
    setChecklistSubmit(true);
    const storedItem = sessionStorage.getItem("persist:root");
    if (storedItem !== null) {
      const parsedItem = JSON.parse(storedItem);
      const anotherValue = parsedItem["currentUserDetails"];
      const parsedItem1 = JSON.parse(anotherValue);
      const roleName = parsedItem1.role_name;
      if (roleName === "credit_head" || roleName === "credit_ops") {
        setIsRoleHead(true);
      }
    } else {
      console.log("User Role not found in local storage.");
    }
    console.log(rows);
    const transformedObject = {
      pre_disbursal_checklist: {},
    };
    const allUserChecked = rows.every((item) => item.user_checked);

    // If allUserChecked is true, handle it accordingly
    if (allUserChecked) {
      setallUserChecked(false);
    } else {
      setallUserChecked(true);
    }
    rows.forEach((item) => {
      const newItem = {
        details: item.details || "",
        doc_url: item.doc_url || null,
        is_checked: item.is_checked || false,
        user_checked: item.user_checked || false,
      };

      transformedObject["pre_disbursal_checklist"][
        item.title.toLowerCase().replace(/ /g, "_")
      ] = newItem;
    });

    console.log(transformedObject);
    try {
      const result = await submitChecklist(
        reduxData,
        transformedObject.pre_disbursal_checklist
      );
      console.log("results", result.data.response_data);
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };

  const handleDisburse = async () => {
    setLoading(true);
    try {
      const result = await submitDisbursal(reduxData, remarks);
      const result1 = await getTransactionDetails(reduxData);
      setUtr(result1.data.response_data.utr);
      setRefId(result1.data.response_data.reference_id);
      setTransAmt(result1.data.response_data.transaction_amount);
      setTransStatus(result1.data.response_data.transaction_status);
      setLoading(false);
      setOpenUTR(true);
      setIsDisburse(true);
    } catch (error) {
      if (error.message) {
        setErrorMessage(error.message);
      }
    }
  };

  const handleUTRClose = () => {
    setOpenUTR(false);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Checklist"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {disbursementSideNav}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <div className="details-box" style={{ height: "68vh" }}>
              <DataGrid
                columns={columns}
                rows={rows}
                style={{ height: "55vh" }}
              />
              <div style={{ display: "flex", flexDirection: "row" }}></div>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                style={{
                  width: "100%",
                }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  className="download-button"
                  onClick={submitCheckListResults}
                >
                  Submit Disbursement Checklist
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  disabled={allUserChecked}
                  className="download-button"
                  onClick={submitPDC}
                >
                  Ready for Disbursement
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  //disabled={allUserChecked || !checklistSubmit || !isRoleHead }
                  // disabled // switch if you want to allow disbursal of loan.
                  className="download-button"
                  onClick={handleOpen}
                >
                  Disburse Loan
                </Button>
                <Dialog open={disburseOpen} onClose={handleClose}>
                  {loading ? ( // Display loader if loading is true
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        marginTop: "150px",
                      }}
                    >
                      <CircularProgress />
                      <Typography>Loading...</Typography>
                    </div>
                  ) : (
                    <>
                      <AppBar position="static">
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                        >
                          <Tab
                            label="Disbursement Details"
                            style={
                              selectedTab === 0
                                ? {
                                    padding: "14px 24px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    transition: "box-shadow 0.3s ease",
                                    color: "white",
                                  }
                                : { color: "white" }
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: "lightgray", // Light color on hover
                                opacity: 0.8,
                              },
                            }}
                          />
                          <Tab
                            label="Transaction Details"
                            style={
                              selectedTab === 1
                                ? {
                                    padding: "14px 24px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    transition: "box-shadow 0.3s ease",
                                    color: "white",
                                  }
                                : { color: "white" }
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: "lightgray", // Light color on hover
                                opacity: 0.8,
                              },
                            }}
                          />
                        </Tabs>
                      </AppBar>
                      <Box p={2}>
                        <div
                          style={{
                            display: selectedTab === 0 ? "block" : "none",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h4"
                              style={{ marginBottom: "10px" }}
                            >
                              Disbursement Details
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Loan ID:
                              </span>{" "}
                              {loanDisburse}
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Beneficiary Account IFSC:
                              </span>{" "}
                              {ifsc}
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Beneficiary Account Number:
                              </span>{" "}
                              {accno}
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Beneficiary Name:
                              </span>{" "}
                              {name}
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Disbursement Amount:
                              </span>{" "}
                              {disburseAmt}
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Already Disbursed:
                              </span>{" "}
                              {isDisbursed ? "Yes" : "No"}
                            </Typography>
                            <Typography style={{ fontSize: "19px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Ready for Disbursement:{" "}
                              </span>{" "}
                              {readyDisburse ? "Yes" : "No"}
                            </Typography>
                            <TextField
                              label="Remarks"
                              variant="outlined"
                              fullWidth
                              multiline
                              minrows={4}
                              margin="normal"
                              value={remarks} // Value of the TextField is controlled by the remarks state
                              onChange={(e) => setRemarks(e.target.value)} // Update the remarks state on change
                              style={{ maxWidth: "600px" }}
                              disabled={isDisbursed || !readyDisburse}
                            />
                          </div>
                          <DialogActions>
                            <Button
                              disabled={isDisbursed || !readyDisburse}
                              onClick={handleDisburse}
                              variant="contained"
                              color="primary"
                            >
                              Disburse Loan
                            </Button>
                          </DialogActions>
                          <Snackbar
                            open={!!errorMessage}
                            autoHideDuration={6000}
                            onClose={() => setErrorMessage("")}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <MuiAlert
                              elevation={6}
                              variant="filled"
                              severity="error"
                            >
                              {errorMessage}
                            </MuiAlert>
                          </Snackbar>
                        </div>
                        <div
                          style={{
                            display: selectedTab === 1 ? "block" : "none",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h4"
                              style={{ marginBottom: "30px" }}
                            >
                              Transaction Details
                            </Typography>
                            <Typography style={{ fontSize: "22px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                Beneficiary Account Number:
                              </span>{" "}
                              {accno}
                            </Typography>
                            <Typography style={{ fontSize: "22px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Transaction Amount:{" "}
                              </span>{" "}
                              {transAmt}
                            </Typography>
                            <Typography style={{ fontSize: "22px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Transaction Status:{" "}
                              </span>{" "}
                              {transStatus}
                            </Typography>
                            <Typography style={{ fontSize: "22px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Reference ID:
                              </span>{" "}
                              {refId}
                            </Typography>
                            <Typography style={{ fontSize: "22px" }}>
                              <span style={{ fontWeight: "bold" }}>UTR:</span>{" "}
                              {utr}
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                </Dialog>
                <Dialog maxWidth="xl" open={openUTR} onClose={handleUTRClose}>
                  <div style={{ padding: "0 20px" }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          backgroundColor: "#d4edda",
                          borderRadius: "4px",
                          marginBottom: "10px",
                        }}
                      >
                        <CheckCircleIcon
                          style={{ color: "#155724", marginRight: "8px" }}
                        />{" "}
                        {/* Tick mark icon */}
                        <Typography
                          variant="body1"
                          style={{ color: "#155724", fontWeight: "bold" }}
                        >
                          Transaction Successful
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "10px 0",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "22px", marginBottom: "10px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Reference ID:
                        </span>{" "}
                        {refId}
                      </Typography>
                      <Typography
                        style={{ fontSize: "22px", marginBottom: "10px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>UTR:</span> {utr}
                      </Typography>
                      <Button
                        onClick={handleUTRClose}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "10px", marginLeft: "auto" }}
                      >
                        Go Back
                      </Button>
                    </div>
                  </div>
                </Dialog>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>

      <Footer />
    </ThemeProvider>
  );
}

export default function checklist() {
  return <Checklist />;
}
