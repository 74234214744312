import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Axios from "axios";
import { base_url1 } from "../../constants";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  verificationSideNav,
  secondaryListItemsDetails,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress, TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import {
  CreateModifyPD,
  getPersonalDiscussion,
  videoKYC,
  getQuestionAndAnswer,
  submitPDAnswers,
  getDocuments,
  getDownloadUrl
} from "./../apiCalls/api";
import { useState } from "react";
import VideoPlayer from "./../Common/VideoPlayer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PdfViewerPopup from "../Common/pdfViewPopup";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mdTheme = createTheme();
const handleChangeInput = (event) => {};
function Discussion() {
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [comments, setComments] = React.useState("");
  const [videoBase64, setvideoBase64] = React.useState("");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [userResponses, setUserResponses] = useState({});
  const [questions, setquestions] = useState([]);
  const [documents, setdocuments] = useState([]);
  const [isPdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [fileName, setfileName] = useState("");
  const [errors, setErrors] = useState({});
  const [downloadUrl, setDownloadUrl] = React.useState();
  const [docsLoading, setDocsLoading] = useState(false);
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const currentUser = useSelector((state) => state.currentUserDetails);
  const makerChecker = useSelector((state) => state.makerCheckAccess);
  const columns = [
    {
      field: "filename",
      headerName: "File Name",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "document_type",
      headerName: "Document Type",
      flex: 1,
      minWidth: 90,
    },
  ];
  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton">
              <Tooltip title="Download File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => downloadSingleFile(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View File" sx={{ mt: -1 }}>
                <IconButton
                  onClick={(e) => handleViewClick(params.row)}
                  style={{ color: "#3f48cc" }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    async function getData() {
      // getting data from database
      setisAPILoading(true);
      try {
        const result = await getPersonalDiscussion(reduxData);
        if (result) {
          setisAPILoading(false);
          setComments(result.data.data[0].description);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      try {
        // 1607
        const result = await videoKYC(reduxData);
        console.log("Video Results", result);
        setvideoBase64(result.data.base64);
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);
  const handleResponseChange = (question, questionId, response) => {
    console.log("errors", errors);
    setUserResponses({
      ...userResponses,
      [questionId]: response,
    });
    if (
      question.response_validation &&
      !validateInput(response, question.response_validation)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [question.id]: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [question.id]: false,
      }));
    }
  };
  useEffect(() => {
    async function getData() {
      const newJSON = {};
      // getting data from database
      setisAPILoading(true);
      setDocsLoading(true);
      try {
        const result = await getQuestionAndAnswer(reduxData);
        if (result) {
          setquestions(result.data.response_data);
          setisAPILoading(false);
          result.data.response_data.forEach((item) => {
            newJSON[item.id] = item.user_response;
          });
          setUserResponses(newJSON);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setisAPILoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
      try {
        const result = await getDocuments(reduxData);
        if (result) {
          setDocsLoading(false);
          console.log("Documents result", result);
          setdocuments(result.data.response_data);
        }
      } catch (error) {
        if (
          error.message === "AxiosError: Request failed with status code 401"
        ) {
          setDocsLoading(false);
          setIsErrorModalOpen(true);
          console.error(error);
        }
      }
    }
    getData();
  }, [reduxData]);

  const handleInputChange = (e) => {
    setComments(e.target.value);
  };
  const [open1, setOpen1] = React.useState(false);
  const [openPopup, setopenPopup] = React.useState(false);
  const handleClose = () => {
    setopenPopup(false);
  };
  const handleOpenPopup = () => {
    setopenPopup(true);
  };
  const handleClick = () => {
    setOpen1(!open1);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/login");
  };
  const validateInput = (value, regex) => {
    const result = new RegExp(regex).test(value);
    const pattern = new RegExp(`^${regex}$`);
    return pattern.test(value);
  };
  const submitForm = async () => {
    setisAPILoading(true);
    try {
      const result = await CreateModifyPD(reduxData, comments);
      if (result) {
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const submitQuestions = async () => {
    setisAPILoading(true);
    try {
      console.log(userResponses);
      const result = await submitPDAnswers(reduxData, userResponses);
      if (result) {
        setisAPILoading(false);
      }
    } catch (error) {
      if (error.message === "AxiosError: Request failed with status code 401") {
        setisAPILoading(false);
        setIsErrorModalOpen(true);
        console.error(error);
      }
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const downloadSingleFile = async (e) => {
    try {
      const result = await getDownloadUrl(
        e.file_url
      );
      setDownloadUrl(result.data.response_data.download_url);
      window.open(result.data.response_data.download_url, "_self");
    }catch (error) {
      if (error.message) {
        console.error("Error:", error);
      }
    }  
  };
  const handleViewClick = (e) => {
    setPdfViewerOpen(true);
    setPdfFile(e.base64_str);
    setfileName(e.filename);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
          isOpen={isErrorModalOpen}
          onClose={handleCloseErrorModal}
        />
      </div>

      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Personal Discussion"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {verificationSideNav}
            <Divider />
            {currentUser.role_name === "credit_head" ||
            currentUser.role_name === "credit_ops" ? (
              <>{secondaryListItemsDetails}</>
            ) : null}
          </List>
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Question & Answers"
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Documents"
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Comments"
                {...a11yProps(2)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          {isAPILoading ? (
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  color: "#3f48cc",
                }}
                size={40}
                thickness={4}
              />
            </Grid>
          ) : null}
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>

            <TabPanel value={value} index={0}>
              {questions ? (
                <div className="details-box">
                  <form>
                    <Grid item xs={12} sx={{ pt: 4 }}>
                      <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                        {questions.map((question) => (
                          <Grid
                            key={question.id}
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            mt={3}
                            style={{zIndex: 0}}
                          >
                            {question.response_options !== null ? (
                              <TextField
                                select
                                id={`select-${question.id}`}
                                label={question.question}
                                variant="outlined"
                                fullWidth
                                value={userResponses[question.id] || ""}
                                onChange={(e) =>
                                  handleResponseChange(
                                    question,
                                    question.id,
                                    e.target.value
                                  )
                                }
                              >
                                {question.response_options
                                  ? question.response_options
                                      .split(";")
                                      .map((option, index) => (
                                        <MenuItem
                                          className="custom-dropdown"
                                          key={index}
                                          value={option}
                                          sx={{
                                            fontFamily:
                                              "'Roboto Condensed', sans-serif",
                                            "&:hover": {
                                              borderLeft: "5px solid #3f48cc",
                                              borderRadius: 1,
                                            },
                                          }}
                                        >
                                          {option}
                                        </MenuItem>
                                      ))
                                  : null}
                              </TextField>
                            ) : (
                              <TextField
                                select={question.response_options !== null}
                                id={`select-${question.id}`}
                                label={question.question}
                                variant="outlined"
                                fullWidth
                                value={userResponses[question.id] || ""}
                                onChange={(e) =>
                                  handleResponseChange(
                                    question,
                                    question.id,
                                    e.target.value
                                  )
                                }
                                error={errors[question.id] || false}
                                helperText={
                                  errors[question.id]
                                    ? "Invalid input. Please check your response."
                                    : ""
                                }
                              />
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </form>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      disabled={
                        !makerChecker ||
                        Object.values(errors).some((value) => value === true)
                      }
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      className="download-button"
                      onClick={submitQuestions}
                    >
                      Submit
                    </Button>
                  </Grid>
                </div>
              ) : null}
            </TabPanel>

            <TabPanel value={value} index={1}>
              {docsLoading ? (<>
                <Grid item xs={12} sx={{ pt: 4 }}>
                  <Box sx={{ width: "100%" }}>
                    <Container maxWidth align="center">
                       <CircularProgress/>
                       <Typography>Loading...</Typography>
                    </Container> 
                  </Box>
                </Grid>
              </>):(<>
              {documents.length > 0 ? (
                <div className="details-box">
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Box sx={{ width: "100%" }}>
                      {/* <img src={pic1} style={{ margin: "1%" }} /> */}
                      <Container maxWidth align="center">
                        <Grid item xs={12}>
                          <Grid>
                            <div style={{ width: "98%" }}>
                              <b>List of Documents</b>
                              <div className="table-container1">
                                <DataGrid
                                  rows={documents}
                                  columns={columns.concat(actionColumn)}
                                  getRowId={(row) => row.filename}
                                  density="comfortable"
                                  // onCellClick={handleEvent}
                                  disableSelectionOnClick={true} // disable selecting whole row
                                  sx={{
                                    "&.MuiDataGrid-root": {
                                      border: "none ",
                                    },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader--sortable":
                                      {
                                        outline: "none !important",
                                      },
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                      whiteSpace: "normal !important",
                                      wordWrap: "break-word !important",
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  </Grid>
                </div>
              ) : null}
              </>)}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="details-box">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  {/* <Button
                    disabled={videoBase64 == ""}
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={handleOpenPopup}
                  >
                    View Video KYC
                </Button> */}
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openPopup}
                    className="videoKyc"
                  >
                    <DialogContent>
                      <Typography
                        sx={{ mr: 4, flex: 1 }}
                        component="div"
                        style={{ fontWeight: "800", textAlign: "center" }}
                      >
                        Video KYC
                      </Typography>
                      {videoBase64 != "" ? (
                        <div>
                          <VideoPlayer base64Data={videoBase64} />
                        </div>
                      ) : null}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} className="download-button">
                        Close
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </Grid>
                <Grid item xs={12} sm={6} md={6} mt={2}>
                  <Typography
                    sx={{ mr: 4, flex: 1, pb: 2 }}
                    component="div"
                    style={{ fontWeight: "800" }}
                  >
                    Comments
                  </Typography>
                  <TextField
                    fullWidth
                    multiline // Add this attribute to enable multi-line input
                    rows={4} // You can adjust the number of rows as needed
                    variant="outlined"
                    name="Personal_Discussion"
                    value={comments}
                    onChange={(e) => handleInputChange(e)}
                    inputProps={{
                      style: {
                        minHeight: "100px", // Use minHeight instead of height for multiline
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    disabled={!makerChecker}
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Grid>
              </div>
            </TabPanel>
          </div>
        </Box>
      </Box>
      <PdfViewerPopup
        open={isPdfViewerOpen}
        onClose={() => setPdfViewerOpen(false)}
        pdfFileName={pdfFile}
        fileName={fileName}
      />
      <Footer />
    </ThemeProvider>
  );
}

export default function discussion() {
  return <Discussion />;
}
