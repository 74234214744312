import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Switch,
} from "react-router-dom";
import DetailView from "./components/Detail_View/kyc";
import React, { Component } from "react";
import logo from "./assets/CS_logo_40_40.png";
import Login from "./components/Login_Signup/login";
import Details from "./components/Case_Review/caseReview";
import CreditInformation from "./components/Detail_View/creditInformation";
import FinancialDetails from "./components/Detail_View/financialdetails";
import LoanDetails from "./components/Common/basicLoanDetails";
import BankStatement from "./components/Detail_View/bankstatement";
import CollateralValue from "./components/Detail_View/collateralvalue";
import PaySlip from "./components/Detail_View/payslip";
import GST from "./components/Detail_View/gst";
import TaxFiling from "./components/Detail_View/taxfiling";
import Verification from "./components/Verification/initiateVerification";
import Summary from "./components/Verification/summary";
import CollateralVerification from "./components/Verification/collateralVerification";
import PersonalDiscussion from "./components/Verification/personalDiscussion";
import MainDashboard from "./components/Main_Dashboard/main_dashboard";
import Request from "./components/disbursement/request";
import Generate from "./components/disbursement/generate";
import Approve from "./components/disbursement/approve";
import Update from "./components/disbursement/update";
import Checklist from "./components/disbursement/checklist";
import ConfirmDisbursement from "./components/disbursement/confirmDisbursement";
import PersonalInterview from "./components/Verification/personalInterview";
import PrintContract from "./components/Verification/printContract";
import FinalDecision from "./components/Verification/finalDecision";
import Cover from "./components/Login_Signup/coverPage";
import Settings from "./components/Settings/settings";
import DocumentVerification from "./components/branchVerifications/documentVerification";
import TechnicalVerification from "./components/branchVerifications/technicalVerification";
import LegalVerification from "./components/branchVerifications/legalVerification";
import RepaymentSchedule from "./components/Verification/repaymentSchedule";
import NotificationService from "./components/notificationServices/whatsappBlast";
import TopUpOffer from "./components/notificationServices/topUpOffer";
import InsuranceDoc from "./components/Detail_View/insurance";
import CibilReport from "./components/reports/cibilReport";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<MainDashboard />} />
        <Route path="/details" element={<Details />} />
        <Route path="/detailview" element={<DetailView />} />
        <Route path="/basicdetails" element={<basicDetails />} />
        <Route path="/creditinformation" element={<CreditInformation />} />
        <Route path="/financialdetails" element={<FinancialDetails />} />
        <Route path="/loandetails" element={<LoanDetails />} />
        <Route path="/bankstatement" element={<BankStatement />} />
        <Route path="/collateralvalue" element={<CollateralValue />} />
        <Route path="/payslip" element={<PaySlip />} />
        <Route path="/gst" element={<GST />} />
        <Route path="/taxfiling" element={<TaxFiling />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/personaldiscussion" element={<PersonalDiscussion />} />
        <Route path="/request" element={<Request />} />
        <Route path="/generate" element={<Generate />} />
        <Route path="/approve" element={<Approve />} />
        <Route path="/update" element={<Update />} />
        <Route path="/personalinterview" element={<PersonalInterview />} />
        <Route path="/printcontract" element={<PrintContract />} />
        <Route path="/finaldecision" element={<FinalDecision />} />
        <Route path="/insurance" element={<InsuranceDoc />} />
        <Route
          path="/collateralverification"
          element={<CollateralVerification />}
        />
        <Route path="/checklist" element={<Checklist />} />
        <Route path="/confirmDisbursement" element={<ConfirmDisbursement />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/cibilReport" element={<CibilReport/>} />
        <Route
          path="/documentVerification"
          element={<DocumentVerification />}
        />
        <Route
          path="/technicalVerification"
          element={<TechnicalVerification />}
        />
        <Route path="/legalVerification" element={<LegalVerification />} />
        <Route path="/repaymentSchedule" element={<RepaymentSchedule />} />
        <Route path="/notificationServices" element={<NotificationService />} />
        <Route path="/topUpOffer" element={<TopUpOffer />} />
      </Routes>
    </Router>
  );
};

export default App;
