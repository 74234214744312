// URL
//export const base_url = "https://ajit-backend-los-dev-eastus.azurewebsites.net";
// URL

//Revert to:
export const base_url1 = "https://goshree-dla-prod-backend-ap-south-india.azurewebsites.net";

//Testing in:
// export const base_url1 = "https://dlav1-dev.southindia.cloudapp.azure.com";
export const base_url2 = "https://dlav1.southindia.cloudapp.azure.com";

// Connection to Production:
// export const base_url1 = "http://127.0.0.1:5001";

//export const base_url1 = "https://dla-backend-dev-south-india.azurewebsites.net";
//export const base_url1 = "https://dlav1-dev.southindia.cloudapp.azure.com";
//process.env.REACT_APP_BACKEND_URL;
//"https://dla-backend-uat-south-india.azurewebsites.net";
// "https://dla-backend-dev-south-india.azurewebsites.net";
//UAT
// https://ajit-backend-dla-uat-eastus.azurewebsites.net/
//DEV
//https://ajit-backend-dla-dev-eastus.azurewebsites.net
//Prod
//https://goshree-dla-prod-backend-ap-south-india.azurewebsites.net/
