import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
    verificationSideNav,
    secondaryListItems,
    disbursementSideNav,
} from "../Side_Nav/listItems";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MenuItem, TextField } from "@material-ui/core";
import LoanDetails from "../Common/basicLoanDetails";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "axios";
import { base_url1 } from "../../constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../Common/SessionModal";
import DoneIcon from '@mui/icons-material/Done';
import {
    getDisbursementDetails,
    submitDisbursal,
    getTransactionDetails,
} from "./../apiCalls/api";
import { useState } from "react";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import AppHeader from "./../Common/header";
import CloseIcon from '@mui/icons-material/Close';
// import {
//     Checkbox,
//     Select,
//     FormControl,
//     InputLabel,
//     FormGroup,
//     FormControlLabel,
// } from "@mui/material";
import Select from "react-select";
import { color } from "chart.js/helpers";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme();
function ConfirmDisbursement() {
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const reduxData = useSelector(
        (state) => state.applications?.loan_basic_info?.Loan_Application_id
    );
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [accountVerified, setaccountVerified] = useState(false);
    const [open, setOpen] = React.useState(true);
    const modeOfTransfer = [
        { value: 'NEFT', label: 'NEFT' },
        { value: 'IMPS', label: 'IMPS' },
        { value: 'RTGS', label: 'RTGS' },
        { value: 'FT', label: 'FT' },
    ];
    const [modeOfTransferSelect, setModeOfTransferSelect] = useState("");

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
        navigate("/login");
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const formatKey = (key) => {
        return key
            .replace(/_/g, " ")
            .replace(/^\w/, (c) => c.toUpperCase())
            .replace(/\b\w/g, (c) => c.toUpperCase());
    };

    const changeTransferMode = (e) => {
        // setModeOfTransferSelect(e.target.value);
    }
    const handleVerifyAccountClick = async () => {
        // try {
        //   const result = await verifyAccountPennyDrop(
        //     data?.loan_id,
        //     handleLogoutButton
        //   );
        //   if (result?.data?.response_message == "SUCCESS") {
        //     setaccountVerified(true);
        //   }
        // } catch (error) { }
    };

    useEffect(async () => {
        try {
            setLoading(true);
            const result = await getDisbursementDetails(reduxData);
            setLoading(false);
            setData(result.data.response_data);
            console.log(result.data.response_data);
        } catch (error) {
            if (error.message === "AxiosError: Request failed with status code 401") {
                setIsErrorModalOpen(true);
                console.log(error);
            }
        }
    }, [])

    // const handleDisburse = async () => {
    //     setLoading(true);
    //     try {
    //       const result = await submitDisbursal(reduxData, remarks);
    //       const result1 = await getTransactionDetails(reduxData);
    //       setUtr(result1.data.response_data.utr);
    //       setRefId(result1.data.response_data.reference_id);
    //       setTransAmt(result1.data.response_data.transaction_amount);
    //       setTransStatus(result1.data.response_data.transaction_status);
    //       setLoading(false);
    //       setOpenUTR(true);
    //       setIsDisburse(true);
    //     } catch (error) {
    //       if (error.message) {
    //         setErrorMessage(error.message);
    //       }
    //     }
    //   };

    return (<>
        <ThemeProvider theme={mdTheme}>
            <div>
                {/* Your component content */}
                <SessionModal
                    isOpen={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                />
            </div>
            <Box sx={{ display: "flex", mb: 6 }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                        }}
                    >
                        <AppHeader
                            open={open}
                            toggleDrawer={toggleDrawer}
                            title="Confirm Disbursement"
                        />
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {disbursementSideNav}
                        <Divider sx={{ my: 1 }} />
                        {/* {secondaryListItems} */}
                    </List>
                </Drawer>
                <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
                    <div style={{ marginTop: "1%" }}>
                        <LoanDetails></LoanDetails>
                        <div className="details-box2" style={{ height: "68vh" }}>
                            <Box sx={{ width: "100%" }}>
                                {/* <div style={{ marginTop: "1%" }}> */}
                                <div className="details-box2">
                                    <div style={{ display: "flex", flexDirection: "row" }}></div>
                                    <Typography style={{ fontSize: "1.5rem", fontWeight: "800", color: "#3f48cc" }} sx={{ ml: 3 }}>Disbursement Details</Typography>
                                    {loading ? ( // Display loader if loading is true
                                        <div
                                            style={{
                                                textAlign: "center",
                                                padding: "20px",
                                                marginTop: "150px",
                                            }}
                                        >
                                            <CircularProgress />
                                            <Typography>Loading...</Typography>
                                        </div>
                                    ) : (
                                        <><Grid container spacing={2} alignItems="flex-start">
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                // sx={{
                                                //   display: "flex",
                                                //   justifyContent: "flex-end",
                                                // }}
                                                style={{
                                                    width: "100%",
                                                    marginTop: "1%",
                                                    marginLeft: "1%",
                                                }}
                                            >
                                                <div style={{ marginTop: "3%" }}>
                                                    {Object.keys(data)
                                                        .sort((a, b) => {
                                                            if (a === "loan_id") return -1;
                                                            if (b === "loan_id") return 1;
                                                            return 0;
                                                        })
                                                        .map((key, index) => (
                                                            <Typography
                                                                key={key}
                                                                sx={{
                                                                    ml: 5, mb: 1,
                                                                    flex: 1,
                                                                    animation: `appearFromSideSmooth 0.6s ease-in-out ${index * 0.15}s forwards`,
                                                                    opacity: 0,
                                                                    transform: "translateX(-30px) scale(0.5)",
                                                                }}
                                                            // style={{color:"#ffffff"}}
                                                            >
                                                                <Typography
                                                                    // whiteSpace="nowrap"
                                                                    sx={{ minWidth: "300px", mr: "10px", display: "inline-block" }}
                                                                    component="div"
                                                                    style={{
                                                                        fontSize: "1rem",
                                                                        //   fontWeight: "700",
                                                                        display: "inline-block",
                                                                    }}
                                                                >
                                                                    {key === "is_disbursed" ?
                                                                        (<span>
                                                                            Already Disbursed
                                                                        </span>
                                                                        ) : (
                                                                            <>{formatKey(key)}</>
                                                                        )}
                                                                </Typography>
                                                                {
                                                                    key === "beneficiary_account_ifsc" ||
                                                                        key === "beneficiary_account_number" ||
                                                                        key === "beneficiary_name" ||
                                                                        key === "loan_id" ?
                                                                        (
                                                                            <b>:{data[key]}</b>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    key === "is_disbursed" ? (
                                                                                        data[key].toLocaleString("en-IN") === "true" ? (
                                                                                            <><span style={{
                                                                                                color: "var(--green-color)",
                                                                                                fontWeight: 700,
                                                                                                // display: "flex"
                                                                                            }}>:<DoneIcon style={{ marginTop: "-3px" }} />
                                                                                                <Typography style={{ fontWeight: 700, display: "inline" }}>Yes</Typography>
                                                                                            </span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <><span style={{
                                                                                                color: "var(--red-color)",
                                                                                                fontWeight: 700,
                                                                                                // display: "flex"
                                                                                            }}>:<CloseIcon style={{ marginTop: "-3px" }} />
                                                                                                <Typography style={{ fontWeight: 700, display: "inline" }}>No</Typography>
                                                                                            </span>
                                                                                            </>
                                                                                        )
                                                                                    ) : key === "ready_for_disbursement" ? (
                                                                                        data[key] === 1 ? (
                                                                                            <><span style={{
                                                                                                color: "var(--green-color)",
                                                                                                fontWeight: 700,
                                                                                            }}>:<DoneIcon style={{ marginTop: "-3px" }} />
                                                                                                <Typography style={{ fontWeight: 700, display: "inline" }}>Yes</Typography>
                                                                                            </span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <><span style={{
                                                                                                color: "var(--red-color)",
                                                                                                fontWeight: 700,
                                                                                            }}>:<CloseIcon style={{ marginTop: "-3px" }} />
                                                                                                <Typography style={{ fontWeight: 700, display: "inline" }}>No</Typography>
                                                                                            </span>
                                                                                            </>
                                                                                        )
                                                                                    ) : key === "disbursement_amount" && data[key] != null ? (
                                                                                        <b>:₹{parseFloat(data[key]).toLocaleString("en-IN", {
                                                                                            maximumFractionDigits: 2,
                                                                                        })}</b>
                                                                                    ) : (
                                                                                        <b>:</b>
                                                                                    )
                                                                                }

                                                                            </>
                                                                        )}

                                                            </Typography>
                                                        ))}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={0.5}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12} md={4.5} sx={{ ml: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={6} md={12} sx={{ mt: 2 }}>
                                                        {!accountVerified ? (
                                                            <>
                                                                <Typography
                                                                    component="div"
                                                                    style={{
                                                                        color: "#3f48cc",
                                                                        fontSize: "1rem",
                                                                        fontWeight: "100",
                                                                        display: "inline-block",
                                                                    }}
                                                                    sx={{ mt: 2, mb:2 }}
                                                                >
                                                                    <a onClick={handleVerifyAccountClick}>
                                                                        Verify the account
                                                                    </a>
                                                                </Typography>
                                                                <Typography style={{ fontSize: "13px", fontStyle: "italic" }}>
                                                                    Using penny drop
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <Typography
                                                                component="div"
                                                                style={{
                                                                    color: "#008000",
                                                                    fontSize: "1rem",
                                                                    fontWeight: "600",
                                                                    display: "inline-block",
                                                                }}
                                                                sx={{ pt: 3 }}
                                                            >
                                                                Account Verified- Penny Drop Successful.
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={5} md={12} sx={{ mt: 2 }}>
                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize: "1rem",
                                                                fontWeight: "100",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}
                                                            sx={{ mt: 2 }}
                                                        >Mode of Transfer:&nbsp;
                                                        <div style={{width: '180px'}}>
                                                            <Select
                                                                placeholder="Select Option"
                                                                options={modeOfTransfer}
                                                                // onChange={(e)=>changeTransferMode(e)}
                                                                style={{ display: "inline-block", width: 20, height: 10 }}
                                                            />
                                                        </div>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{ mt: 3 }}>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        md={12}
                                                        sx={{
                                                            display: "flex",
                                                        }}
                                                        style={{
                                                            width: "98%",
                                                            // marginTop: "3%",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize: "1rem",
                                                                fontWeight: "100",
                                                                alignItems: "center",
                                                                flexWrap: "wrap",
                                                            }}
                                                            sx={{ mt: 2 }}
                                                        >
                                                            {/* <a onClick={handleVerifyAccountClick}> */}
                                                            <CircularProgress size={"0.7rem"} style={{ color: "#3f48cc" }} />&nbsp;Checking Balance Status...
                                                            {/* </a> */}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        md={12}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                        style={{
                                                            // width: "95%",
                                                            marginTop: "15%",
                                                            marginRight: "1%"
                                                        }}
                                                    >
                                                        <Button
                                                            sx={{ textTransform: "none" }}
                                                            variant="contained"
                                                            className="download-button"
                                                            // onClick={handleDisburse}
                                                            // onClick={submitPDC}
                                                            // disabled={isDisbursed || !readyDisburse}
                                                        >Confirm Disbursement
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid></>)}
                                </div>
                            </Box>
                        </div>
                    </div>
                </Box>
            </Box>

            <Footer />
        </ThemeProvider>
    </>)
}

export default function confirmDisbursement() {
    return (<ConfirmDisbursement />);
}