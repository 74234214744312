import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  mainListItems,
  secondaryListItems,
  mainListItemsIndividual,
} from "../Side_Nav/listItems";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import LoanDetails from "../Common/basicLoanDetails";
import { base_url1 } from "../../constants";
import { useSelector } from "react-redux";
import Axios from "axios";
import { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppHeader from "./../Common/header";
import Footer from "./../Common/footer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  insuranceDetails,
  getDownloadUrl
} from "./../apiCalls/api";
import SessionModal from "./../Common/SessionModal";
import { Alert, CircularProgress } from "@mui/material";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const drawerWidth = 240;

const currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});




const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function Insurance() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState();
  const reduxData = useSelector(
    (state) => state.applications?.loan_basic_info?.Loan_Application_id
  );
  const currentUser = useSelector((state) => state.currentUserDetails);
  const loanType = useSelector(
    (state) => state.applications?.loan_basic_info?.loan_type
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        //   const result = await insuranceDetails(reduxData);
        const result = await insuranceDetails(reduxData);
        if (result) {
          console.log(result?.data?.data);
          setData(result?.data?.data);
          setLoading(false);
        }
        else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setShowError(true);
        //   if (
        //     error.message === "AxiosError: Request failed with status code 401"
        //   ) {
        //     setIsErrorModalOpen(true);
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <div>
        {/* Your component content */}
        <SessionModal
        // isOpen={isErrorModalOpen}
        // onClose={handleCloseErrorModal}
        />
      </div>
      <Box sx={{ display: "flex", mb: 6 }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <AppHeader
              open={open}
              toggleDrawer={toggleDrawer}
              title="Insurance"
            />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {loanType == "Individual" ? (
            <List component="nav">
              {mainListItemsIndividual}
              <Divider />
              {currentUser.role_name === "credit_head" ||
                currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          ) : (
            <List component="nav">
              {mainListItems}
              <Divider />
              {currentUser.role_name === "credit_head" ||
                currentUser.role_name === "credit_ops" ? (
                <>{secondaryListItems}</>
              ) : null}
            </List>
          )}
        </Drawer>
        <Box sx={{ width: "100%" }} style={{ marginTop: "5%" }}>
          <div style={{ marginTop: "1%" }}>
            <LoanDetails></LoanDetails>
            <TabPanel value={value} index={0}>
              {/* {filteredDocumentsGuarantor.length > 0 ? ( */}
              <div className="details-box">
                <Grid item xs={12} sx={{ pt: 4 }}>
                  <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                    <Grid item xs={6} sm={6} md={6}>
                      <Typography
                        sx={{ mr: 4, flex: 1, pt: 2, pb: 2 }}
                        component="div"
                        style={{ fontWeight: "800", color: "#3f48cc" }}
                      >
                        Insurance Details
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {currentUser.role_name == "credit_head" ? (<>
                  {!loading ? (
                    <>
                    {showError ? (<>
                    <Grid>
                      <Alert severity="error">No Data Available</Alert>
                    </Grid>
                    </>):(<>
                    {/* {filteredDocumentsGuarantor.length > 0 ? ( */}
                    <Box sx={{ width: "100%" }}>
                      {/* <img src={pic1} style={{ margin: "1%" }} /> */}
                      <Grid item xs={12} sx={{ pt: 4 }}>
                        <Grid>
                          <div style={{ width: "98%" }}>
                            <Container maxWidth align="center">
                              <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
                                <Grid item xs={4} sx={{ alignItems: "center" }}>
                                  <Typography sx={{ fontSize: "0.5rem" }}>EMI Amount</Typography>
                                  <Typography>{currencyFormatter.format(data?.emi_amount || 0)}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ alignItems: "center" }}>
                                  <Typography>Insurance Amount</Typography>
                                  <Typography>{currencyFormatter.format(data?.insurance_amount || 0)}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ alignItems: "center" }}>
                                  <Typography>Loan Amount</Typography>
                                  <Typography>{currencyFormatter.format(data?.loan_amount || 0)}</Typography>
                                </Grid>
                              </Grid>
                            </Container>
                            <div style={{ margin: "5%", display: "flex", justifyContent: "center", fontWeight: "700" }}>Nominee Details</div>
                            <Grid sx={{ m: 3 }}>
                              <div style={{ display: "flex" }}>
                                <Typography sx={{ minWidth: "100px" }}>Name</Typography>
                                <Typography>:{data?.nominee_details?.name}</Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography sx={{ minWidth: "100px" }}>Dob</Typography>
                                <Typography>:{data?.nominee_details?.dob}</Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography sx={{ minWidth: "100px" }}>Address</Typography>
                                <Typography>:{data?.nominee_details?.address}</Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography sx={{ minWidth: "100px" }}>Pincode</Typography>
                                <Typography>:{data?.nominee_details?.pincode}</Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography sx={{ minWidth: "100px" }}>Relation</Typography>
                                <Typography>:{data?.nominee_details?.relation}</Typography>
                              </div>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    </>)}
                  </>) : (<>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        // marginTop: "150px",
                      }}
                    >
                      <CircularProgress />
                      <Typography>Loading...</Typography>
                    </div>
                  </>)}
                </>
                ) : null}
              </div>
            </TabPanel>
          </div>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default function InsuranceDoc() {
  return <Insurance />;
}
